import { useState } from "react"

const Cities = () => {

    const [state, setState] = useState()
    // new cities
    /*
    Tampa city, Florida
Wichita city, Kansas
Arlington city, Texas
Aurora city, Colorado
New Orleans city, Louisiana
*/
    const citiesByState = {
        NY: ["New York, NY", "Buffalo, NY", "Rochester, NY", "Yonkers, NY", "Syracuse, NY"],
        CA: [
            "Los Angeles, CA", "San Diego, CA", "San Jose, CA", "San Francisco, CA", "Fresno, CA",
            "Sacramento, CA", "Long Beach, CA", "Oakland, CA", "Bakersfield, CA", "Anaheim, CA",
            "Stockton, CA", "Riverside, CA", "Chula Vista, CA", "Irvine, CA", "Fremont, CA",
            "Santa Clarita, CA", "Oxnard, CA", "Fontana, CA", "Moreno Valley, CA", "Glendale, CA",
            "Huntington Beach, CA", "Modesto, CA", "San Bernardino, CA", "Santa Rosa, CA",
            "Oceanside, CA", "Garden Grove, CA", "Rancho Cucamonga, CA", "Ontario, CA", "Elk Grove, CA",
            "Corona, CA", "Lancaster, CA", "Palmdale, CA", "Salinas, CA", "Pomona, CA", "Hayward, CA",
            "Escondido, CA", "Sunnyvale, CA", "Pasadena, CA", "Orange, CA", "Fullerton, CA",
            "Thousand Oaks, CA", "Visalia, CA", "Simi Valley, CA", "Concord, CA", "Roseville, CA",
            "Victorville, CA", "Santa Clara, CA", "Vallejo, CA", "Berkeley, CA", "El Monte, CA",
            "Downey, CA", "Costa Mesa, CA", "Inglewood, CA", "Carlsbad, CA", "San Buenaventura (Ventura), CA",
            "Fairfield, CA", "West Covina, CA", "Murrieta, CA", "Norwalk, CA", "Burbank, CA",
            "Antioch, CA", "Daly City, CA", "Temecula, CA"
        ],
        IL: ["Chicago, IL", "Aurora, IL", "Rockford, IL", "Joliet, IL", "Naperville, IL", "Springfield, IL", "Peoria, IL", "Elgin, IL"],
        TX: [
            "Houston, TX", "San Antonio, TX", "Dallas, TX", "Austin, TX", "Fort Worth, TX",
            "El Paso, TX", "Arlington, TX", "Corpus Christi, TX", "Plano, TX", "Laredo, TX",
            "Lubbock, TX", "Garland, TX", "Irving, TX", "Amarillo, TX", "Grand Prairie, TX",
            "Brownsville, TX", "Pasadena, TX", "McKinney, TX", "Mesquite, TX", "McAllen, TX",
            "Killeen, TX", "Frisco, TX", "Waco, TX", "Carrollton, TX", "Denton, TX", "Midland, TX",
            "Abilene, TX", "Beaumont, TX", "Round Rock, TX", "Odessa, TX", "Richardson, TX",
            "Lewisville, TX", "Tyler, TX", "Pearland, TX", "College Station, TX"
        ],
        AZ: ["Phoenix, AZ", "Tucson, AZ", "Mesa, AZ", "Chandler, AZ", "Glendale, AZ", "Scottsdale, AZ", "Gilbert, AZ", "Tempe, AZ", "Peoria, AZ", "Surprise, AZ"],
        // Additional states with their corresponding cities
        PA: ["Philadelphia, PA", "Pittsburgh, PA"],
        FL: ["Jacksonville, FL", "Miami, FL", "Tampa, FL", "St. Petersburg, FL", "Orlando, FL", "Hialeah, FL", "Fort Lauderdale, FL", "Port St. Lucie, FL", "Pembroke Pines, FL", "Hollywood, FL"],
        OH: ["Columbus, OH", "Cleveland, OH", "Cincinnati, OH", "Toledo, OH", "Akron, OH", "Dayton, OH"],
        NC: ["Charlotte, NC", "Raleigh, NC", "Greensboro, NC", "Durham, NC", "Winston-Salem, NC", "Fayetteville, NC", "Cary, NC", "Wilmington, NC", "High Point, NC"],
        IN: ["Indianapolis, IN", "Fort Wayne, IN", "Evansville, IN"],
        WA: ["Seattle, WA", "Spokane, WA", "Tacoma, WA", "Vancouver, WA", "Bellevue, WA"],
        CO: ["Denver, CO", "Colorado Springs, CO", "Aurora, CO", "Fort Collins, CO", "Lakewood, CO", "Thornton, CO", "Arvada, CO"],
        OK: ["Oklahoma City, OK", "Tulsa, OK", "Norman, OK", "Broken Arrow, OK"],
        TN: ["Nashville, TN", "Memphis, TN", "Knoxville, TN", "Chattanooga, TN", "Clarksville, TN", "Murfreesboro, TN"],
        NV: ["Las Vegas, NV", "Henderson, NV", "Reno, NV", "North Las Vegas, NV", "Sparks, NV"],
        MA: ["Boston, MA", "Worcester, MA", "Springfield, MA", "Lowell, MA", "Cambridge, MA"],
        OR: ["Portland, OR", "Eugene, OR", "Salem, OR", "Gresham, OR", "Hillsboro, OR"],
        LA: ["New Orleans, LA", "Baton Rouge, LA", "Shreveport, LA", "Lafayette, LA"],
        MI: ["Detroit, MI", "Grand Rapids, MI", "Warren, MI", "Sterling Heights, MI", "Ann Arbor, MI", "Lansing, MI"],
        MD: ["Baltimore, MD"],
        WI: ["Milwaukee, WI", "Madison, WI"],
        NM: ["Albuquerque, NM", "Las Cruces, NM", "Rio Rancho, NM"],
        MO: ["Kansas City, MO", "St. Louis, MO", "Springfield, MO", "Independence, MO"],
        GA: ["Atlanta, GA", "Augusta, GA", "Columbus, GA", "Savannah, GA", "Athens, GA", "Sandy Springs, GA", "South Fulton, GA", "Macon-Bibb County, GA"],
        KS: ["Wichita, KS", "Overland Park, KS", "Kansas City, KS", "Olathe, KS", "Topeka, KS"],
        AL: ["Birmingham, AL", "Montgomery, AL", "Mobile, AL", "Huntsville, AL", "Tuscaloosa, AL", "Hoover, AL", "Auburn, AL", "Dothan, AL", "Decatur, AL", "Florence, AL", "Vestavia Hills, AL"],
        UT: ["Salt Lake City, UT", "West Valley City, UT", "Provo, UT"],
        IA: ["Des Moines, IA", "Cedar Rapids, IA", "Davenport, IA"],
        RI: ["Providence, RI"],
        SC: ["Columbia, SC", "Charleston, SC", "North Charleston, SC"],
        VA: ["Virginia Beach, VA", "Norfolk, VA", "Chesapeake, VA", "Richmond, VA", "Newport News, VA", "Alexandria, VA", "Hampton, VA"],
        ID: ["Boise City, ID", "Meridian, ID", "Nampa, ID"],
        SD: ["Sioux Falls, SD"],
        NE: ["Omaha, NE", "Lincoln, NE"],
        NJ: ["Newark, NJ", "Jersey City, NJ", "Paterson, NJ", "Elizabeth, NJ"],
        AK: ["Anchorage, AK", "Fairbanks, AK", "Juneau, AK", "Badger, AK", "Knik-Fairview, AK", "College, AK", "North Lakes, AK", "Tanaina, AK"],
        HI: ["Urban Honolulu, HI"],
        CT: ["Bridgeport, CT", "New Haven, CT", "Stamford, CT", "Hartford, CT", "Waterbury, CT"],
        MN: ["Minneapolis, MN", "Saint Paul, MN", "Rochester, MN"],
        MS: ["Jackson, MS"],
        KY: ["Louisville, KY", "Lexington, KY"],
        AR: ["Little Rock, AR", "Fayetteville, AR", "Fort Smith, AR", "Springdale, AR", "Jonesboro, AR", "Rogers, AR"],
        MT: ["Billings, MT"],
        ND: ["Fargo, ND"],
        NH: ["Manchester, NH"],
        RI: ["Providence, RI"]
    };

    const stateAbbreviationsToNames = {
        AL: "Alabama",
        AK: "Alaska",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DE: "Delaware",
        FL: "Florida",
        GA: "Georgia",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PA: "Pennsylvania",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming",
        DC: "District of Columbia"
    };

    const getStateFullName = (abbreviation) => {
        return stateAbbreviationsToNames[abbreviation] || abbreviation;
    };

    const sortedStates = Object.keys(citiesByState).sort((a, b) => {
        const fullNameA = getStateFullName(a);
        const fullNameB = getStateFullName(b);
        return fullNameA.localeCompare(fullNameB);
    });


    return (
        <>
            <style>

                {
                    `
        body {
            font-family: Arial, sans-serif;
       
          
            font-size: 16px;
 
        }
        h1, h2 {
            color: #333;
            margin-bottom: 6px;
            margin-top: 26px;
        
        }
        p, li {
            line-height: 1.6;
            color: #666;
        
        }
        `
                }
            </style>
            <div className="lg-text-container" style={{ maxWidth: '800px' }}>
                <h1>Supported Cities</h1>
                {sortedStates.map((stateAbbr) => {
                    const cities = citiesByState[stateAbbr].sort((a, b) =>
                        a.replace(/, \w\w$/, '').localeCompare(b.replace(/, \w\w$/, ''))
                    );
                    return (
                        <div key={stateAbbr}>
                            <h2 className="cities-title" >{getStateFullName(stateAbbr)}</h2>
                            <ul className="cities-grid">
                                {cities.map((city) => (
                                    // Here we still replace the state abbreviation for display
                                    <li key={city}>{city.replace(/, \w\w$/, '')}</li>
                                ))}
                            </ul>
                        </div>
                    );
                })}

                <h2>Request Your City</h2>
                <p>
                    Don't see your city listed? <a href="mailto:requestaddress@foodscoutapp.com">Email us</a> and we'll let you know when we're available in your area.
                </p>

                <button className="download-btn floating-download" onClick={() => {
                    window.location.href = "https://apps.apple.com/us/app/food-scout-compare-fees/id6449804396";
                }}>
                    <img src={"/assets/web/download.svg"} alt="download on the appstore" />
                </button>


            </div>
        </>
    )
}

export default Cities
